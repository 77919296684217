// media query for mobile
@media (max-width: 767px) {
  .event-button {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
  }

  .hidden-mobile {
    display: none;
  }

  .event-pay-buttons {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 2000;
    background-color: #344675 !important;
    // top shadow
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    padding: 18px;
  }

  .modal.show .modal-dialog {
    transform: unset !important
}
}

@media screen and (max-width: 576px) {
  .events-page-button {
    display: none;
  }
}


.event-card2 {
  position: relative;
  overflow: hidden;
}

.event-card2:hover .card-content::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, transparent, black);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  height: 100%;
}

.event-card2:hover .card-content::before {
  opacity: 1;
}

.event-button2 {
  visibility: hidden;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.event-card2:hover .event-button2 {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.ticketCard {
  .ribbon {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 100;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: left;
  }
  
  .ribbon span {
    font-size: 10px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    width: 100px;
    display: block;
    background: red;
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 19px;
    right: -21px;
  }
}

.halloween-logo {
  margin: 0 8px;
  font-size: 1.2em;
}